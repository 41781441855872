import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LiveStreamControllerService, LivestreamCampaignControllerService } from '@soctrip/angular-livestream-service';
import { Subject, forkJoin, of, takeUntil } from 'rxjs';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-livestream-view',
  templateUrl: './livestream-view.component.html',
  styleUrls: ['./livestream-view.component.scss'],
})
export class LivestreamViewComponent implements OnInit, OnDestroy {
  readonly translatePrefix = 'section-live.';
  protected readonly BE_URL = environment.BE_URL.slice(0, -1);
  protected readonly SOCIAL_URL = environment.LOGIN_URL;

  unsubscribe$ = new Subject();
  liveId = '';
  userId = '';
  token = '';
  infoLive: any;
  userInfo: any;
  loading = false;

  isComingSoon = false;
  banner = '';
  comingSoonMessage = '';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private livestreamService: LiveStreamControllerService,
    private livestreamCampaignService: LivestreamCampaignControllerService,
    private el: ElementRef,
    private translateService: CustomTranslateService,
  ) {
    this.liveId = this.route.snapshot.paramMap.get('id') ?? '';
    this.userId = this.route.snapshot.queryParamMap.get('userId') ?? '';
    this.token = this.route.snapshot.queryParamMap.get('token') ?? '';
    this.comingSoonMessage = this.route.snapshot.queryParamMap.get('mcs') ?? '';

    localStorage.clear();
    localStorage.setItem('liveToken', this.token);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: { preventDefault: () => void }) {
    localStorage.removeItem('liveToken');
  }

  ngOnInit(): void {
    const lang = this.route.snapshot.queryParams?.['lang'];
    if (['vi', 'en'].includes(lang)) {
      this.translateService.setLanguage(lang);
    } else {
      this.translateService.setLanguage('en');
    }

    this.loading = true;
    forkJoin([
      this.livestreamService.livesIdGet(this.liveId),
      this.userId ? this.http.get(`${environment.BE_URL}user/users/${this.userId}`) : of(undefined),
      this.livestreamCampaignService.livestreamCampaignsLivesLiveIdGet(this.liveId),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (results: any) => {
          const [liveRes, userRes, campaignRes] = results;

          this.infoLive = liveRes?.data;
          this.userInfo = userRes?.data;
          this.banner = campaignRes?.data?.avatar_id;

          if (this.infoLive?.status === 'UPCOMING') {
            this.isComingSoon = true;
          }

          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });

    this.route.params.subscribe(() => {
      this.removeIframe();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.removeIframe();
    localStorage.removeItem('liveToken');
  }

  removeIframe() {
    const iframeContainer = this.el.nativeElement.querySelector('#iframe-container');
    const iframe = iframeContainer?.querySelector('iframe');
    if (iframe) {
      iframeContainer.removeChild(iframe);
    }
  }

  onExitLive() {
    this.removeIframe();
    const url = `${environment.FE_URL_SALE}livestream`;
    // window.location.replace(url);
    window.open(url, '_selt');
  }

  eCommerceMessage(e: any) {
    parent.postMessage(JSON.stringify(e), '*');
    const type = e.type;
    if (type === 'LIVESTREAM_STARTED') {
      this.isComingSoon = false;
    }
  }

  onSignIn() {
    window.location.href = `${environment.LOGIN_URL}/login?r=${environment.FE_URL_SALE}livestream/room/${this.liveId}`;
  }

  onSignUp() {
    window.location.href = `${environment.LOGIN_URL}/signup`;
  }
}
