import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopCatalog, ShopCatalogControllerService } from '@soctrip/angular-catalog-service';
import { ShopControllerService } from '@soctrip/angular-shop-service';
import { StockProductControllerService, StockProductCreateDTO } from '@soctrip/angular-stock-service';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { Subject, catchError, forkJoin, of, switchMap, takeUntil } from 'rxjs';
import { SeverityLevel } from 'src/app/core/constants/severity.enum';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  CreateProductManagement,
  Stocks,
  Variation,
  VariationValue,
} from 'src/app/core/models/classes/product/create-product.model';
import { Attachment } from 'src/app/core/models/interfaces/attachment';
import { ProductGet } from 'src/app/core/models/interfaces/product/product-detail';
import { AppService } from 'src/app/core/services/app.service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { FileService } from 'src/app/core/services/file.service';
import { ShopService } from 'src/app/core/services/shop.service';
import { TabStepEnum } from 'src/app/features/guideline/enum/tab-step.enum';
import { TabGuidelineService } from 'src/app/features/guideline/services/tab-guideline.service';
import { environment } from 'src/environments/environment';
import { convertCategoriesTree, findCategory } from '../../utils/convert-categories-tree';
import { AttachmentFormType, PricingFormType, ProductFormType } from './product-edit.model';
import { AddressDirectoryControllerService } from '@soctrip/angular-shipment-service';
import { IRegion } from 'src/app/core/models/interfaces/shipment';
@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
  providers: [ConfirmationService],
})
export class ProductEditComponent implements OnInit, OnDestroy {
  @Input() isViewMode: boolean = false;
  @Input() isIframe: boolean = false;
  @ViewChild('form') form: NgForm;

  protected readonly SoctripIcons = SoctripIcons;
  protected readonly actionPrefix = 'section-action.';
  protected readonly managementPrefix = 'section-product-n-food-management.';
  protected readonly descriptionPrefix = 'text-description.';
  readonly isProduction = environment.state === 'PRODUCTION';

  shopId: string = '';
  shopCode: string = '';

  unsubscribe$ = new Subject();
  isUpdating = false;
  isUploadImages = false;
  isCreatingAttributes = false;
  isFetching = false;
  isSubmitted = false;

  productId = 'new';
  productData: ProductGet;

  attachmentsDisplay: any[] = [];
  categories: ShopCatalog[] = [];
  categoriesDisplay: any[] = [];

  productForm: ProductFormType;

  deliveryAreas: any[] = [];
  countryId: string = '';

  isValidDes = true;
  isAllowMultipleAddress: boolean = false;
  isNotAccepted = false;
  is_polity_verified = false;
  is_information_verified = false;
  nationwideId = '00000000-0000-0000-0000-000000000000';

  initProductForm() {
    this.productForm = {
      attachments: [],
      name: '',
      variants: [],
      in_stock: undefined,
      price: undefined,
      sale_price: undefined,
      sku: undefined,
      s_sku: '',
      pricings: [],
      is_public: false,
      hashtags: [],
      avatarIndex: 0,
      category: undefined,
      attributes: [],
      description: '',
      products_related: [],
      products_combo: [],
      parcel: {
        width: undefined,
        length: undefined,
        height: undefined,
        weight: undefined,
      },
      is_sale_direct: false,
      auto_confirm: false,
      branch_id: '',
      sale_area: [this.nationwideId]
    };
  }

  constructor(
    public fileService: FileService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private stockService: StockProductControllerService,
    private shopCatalogControllerService: ShopCatalogControllerService,
    private shopControllerService: ShopControllerService,
    private localShopService: ShopService,
    private tabGuidelineService: TabGuidelineService,
    private translator: CustomTranslateService,
    private appService: AppService,
    private addressDirectoryService: AddressDirectoryControllerService,
  ) {}

  ngOnInit() {
    this.productId = this.route.snapshot.paramMap.get('productId') || 'new';

    if (this.isViewMode) {
      this._fetchProductDataIframe();
    } else {
      this.appService.getShopData().subscribe((data) => {
        if (data?.id) {
          this.shopId = data.id;
          this.isNotAccepted = data.accepted_status !== 'ACCEPTED' || data.legality_status !== 'ACCEPTED';
          this.is_polity_verified = data.is_policy_verified;
          this.is_information_verified = data.is_information_verified;
          this.shopCode = data.code;
          this.countryId = data.country?.id ?? '';
          this._fetchProvince(this.countryId);
          this.initProductForm();
          this._fetchProductData();
        }
      });
    }
  }

  get isCreateMode() {
    return Boolean(this.productId && this.productId === 'new');
  }

  _fetchProductData() {
    this.isFetching = true;
    forkJoin({
      catalog: this.shopCatalogControllerService.shopCatalogsMyShopObjectIdGet(this.shopId, 0, 200),
      multipleAddress: this.localShopService.checkMultipleAddress(this.shopCode),
    })
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((results) => {
          const { catalog, multipleAddress } = results;

          this.isAllowMultipleAddress = multipleAddress.data;

          if (catalog?.data?.data) {
            this.categories = catalog?.data?.data;
            this.categoriesDisplay = convertCategoriesTree(catalog?.data?.data);
          }
          if (this.productId != 'new') {
            return this.stockService.productsIdGet(this.productId);
          } else {
            return of(undefined);
          }
        }),
      )
      .subscribe({
        next: (res) => {
          if (res?.data) {
            this.productData = res?.data;
            this.initProductForm();
            this._onLoadProduct();
          } else {
            this.isFetching = false;
          }
        },
        error: () => {
          this.isFetching = false;
        },
      });
  }

  _fetchProductDataIframe() {
    this.isFetching = true;
    this.stockService
      .productsIdInfoGet(this.productId)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((res) => {
          this.productData = res.data;
          this.shopId = res.data?.shop?.id;
          this.deliveryAreas = res.data?.sale_area ?? [];

          if (this.deliveryAreas.length === 0) {
            this.deliveryAreas = [
              {
                id: this.nationwideId,
                name: "Nationwide",
                code_name: null,
              },
            ];
          }

          return this.shopControllerService.shopsIdInfoGet(this.shopId);
        }),
        switchMap((res) => {
          return this.localShopService.checkMultipleAddress(res.data.code);
        }),
      )
      .subscribe({
        next: (res) => {
          this.isAllowMultipleAddress = res.data;
          this.initProductForm();
          this._onLoadProduct();
        },
        error: () => {
          this.isFetching = false;
          this.router.navigate(['not-found'], {
            relativeTo: this.router.routerState.root.firstChild,
          });
        },
      });
  }

  _fetchProvince(countryId: string) {
    this.addressDirectoryService.addressProvincesGet(countryId).subscribe({
      next: (res) => {
        this.deliveryAreas = res?.data?.map((province: IRegion) => ({
          id: province.province_id,
          code: province.code_name,
          name: province.province_name,
          postal_code: province.postal_code,
        }));
        this.deliveryAreas.unshift({
          id: this.nationwideId,
          name: this.translator.transform(this.managementPrefix + 'nationwide'),
          code: 'nationwide',
          postal_code: ''
        });
      },
      error: () => {
        this.deliveryAreas = [];
      },
    });
  }

  _onLoadProduct() {
    const { products_related, products_combo } = this.productData;
    const sources = [];
    if (products_related?.length > 0) {
      if (this.isViewMode) {
        sources.push(this.stockService.productsListGet(products_related ?? []).pipe(catchError(() => of(undefined))));
      } else {
        sources.push(
          this.stockService.productsManagementsGet(products_related ?? []).pipe(catchError(() => of(undefined))),
        );
      }
    } else {
      sources.push(of(undefined));
    }
    if (products_combo?.length > 0) {
      if (this.isViewMode) {
        sources.push(this.stockService.productsListGet(products_combo ?? []).pipe(catchError(() => of(undefined))));
      } else {
        sources.push(
          this.stockService.productsManagementsGet(products_combo ?? []).pipe(catchError(() => of(undefined))),
        );
      }
    }
    forkJoin(sources).subscribe({
      next: (results) => {
        const [productsRelatedRes, productsComboRes] = results;
        this.productForm.products_related = productsRelatedRes?.data ?? [];
        this.productForm.products_combo = productsComboRes?.data ?? [];
        this._onLoadData();
        this.isFetching = false;
      },
      error: () => {
        this._onLoadData();
        this.isFetching = false;
      },
      complete: () => {
        this._onLoadData();
        this.isFetching = false;
      },
    });
  }

  _onLoadData() {
    this._onLoadBasicInfo();
    this._onLoadAttachments();
    this._onLoadStocksToPricing();
    this._onLoadPricesToVariants();
    this._onLoadCategories();
    this._onLoadAttributes();
    this._onLoadShipmentValue();
  }

  _onLoadBasicInfo() {
    const {
      name,
      hashtags,
      description,
      original_price_after_tax,
      price_after_tax,
      is_public,
      sku,
      quantity,
      s_sku,
      auto_confirm,
      branch_id,
      sale_area
    } = this.productData;
    this.productForm = {
      ...this.productForm,
      name: name ?? '',
      hashtags: hashtags?.filter((hashtag) => hashtag?.length > 2) ?? [],
      in_stock: quantity,
      price: original_price_after_tax ?? 1,
      sale_price: price_after_tax ?? 1,
      is_public: is_public,
      sku: sku,
      description: description ? description : '',
      s_sku: s_sku,
      auto_confirm: auto_confirm,
      branch_id: branch_id,
      sale_area:
        Array.isArray(sale_area) && sale_area.length > 0
          ? sale_area.map((province: any) => province.id)
          : [this.nationwideId],
    };
  }

  checkAttachmentLink(url: string) {
    const imageUri = `${environment.BE_URL}${environment.SERVICES?.['STORAGE']}/files`;
    const videoUri = `${environment.BE_URL}${environment.SERVICES?.['STORAGE']}/files/stream-video`;
    return (
      (url.indexOf(imageUri) > -1 && url.indexOf('webp') > -1) ||
      (url.indexOf(videoUri) > -1 && url.indexOf('webm') > -1) ||
      this.fileService.isYoutubeUrl(url)
    );
  }

  _onLoadAttachments() {
    const { attachments, avatar } = this.productData;
    const avatarIndex = attachments?.findIndex((att: Attachment) => att.id === avatar?.id) || 0;

    const attachmentsForm: AttachmentFormType[] = (attachments ?? []).map((att, index) => {
      return {
        type: this.checkAttachmentLink(att.id) ? 'link' : 'server',
        data: att,
        index,
      };
    });

    this.attachmentsDisplay = attachments ? attachmentsForm : [];

    this.productForm = {
      ...this.productForm,
      attachments: attachments ? attachmentsForm : [],
      avatarIndex,
    };
  }

  _onLoadPricesToVariants() {
    const { prices } = this.productData;

    this.productForm.variants = prices.map((price) => ({
      key: price?.name,
      values: price.variations.map((variation) => ({ data: variation?.name })),
    }));
  }

  _onLoadStocksToPricing() {
    const { prices, attachments, stocks } = this.productData;
    const v1 = prices?.[0];
    const v2 = prices?.[1];
    const pricings: PricingFormType[] = v1?.variations?.map((variation, variationIndex) => {
      const stockNotV2 = stocks.find((stock) => {
        return stock?.order_first === variationIndex + 1;
      });
      const pricing: PricingFormType = {
        key: v1.name,
        value: variation?.name,
        imageIndex: attachments?.findIndex((att) => att?.id === variation?.image?.id),
        options: v2?.name
          ? stocks
              .filter((stock) => stock.order_first === variationIndex + 1)
              .map((stock) => {
                return {
                  key: v2.name,
                  value: stock.variation_second?.name,
                  price: stock?.original_price_after_tax,
                  in_stock: stock?.quantity,
                  sale_price: stock?.price_after_tax,
                  sku: stock?.sku,
                  image: stock?.image,
                  s_sku: stock?.s_sku || '',
                };
              })
          : [
              {
                price: stockNotV2?.original_price_after_tax ?? 1,
                sale_price: stockNotV2?.price_after_tax ?? 1,
                in_stock: stockNotV2?.quantity ?? 1,
                sku: stockNotV2?.sku,
                s_sku: stockNotV2?.s_sku || '',
              },
            ],
      };
      return pricing;
    });
    this.productForm.pricings = pricings || [];
  }

  _onLoadCategories() {
    if (this.isViewMode) {
      this.productForm.category = this.productData?.catalogs?.[0];
    } else {
      const catalog_ids = this.productData.catalog_ids as string[];
      const categories = catalog_ids
        ? catalog_ids.map((id) => {
            return findCategory(this.categoriesDisplay, id);
          })
        : [];

      this.productForm.category = categories?.[0];
    }
  }

  _onLoadAttributes() {
    const attributes = Array.isArray(this.productData?.attributes) ? [...this.productData.attributes] : [];
    this.productForm.attributes = [...attributes].map((attribute) => ({
      id: attribute.id,
      keyword: attribute.value,
      value: attribute.value,
      valueAttr: {
        id: attribute?.values[0].id,
        keyword: attribute?.values[0].value,
        value: attribute?.values[0].value,
        is_parent: attribute?.values[0].is_parent,
        parent_id: attribute?.values[0].parent_id,
      },
      is_parent: attribute.is_parent,
    }));
  }

  _onLoadShipmentValue() {
    const { parcel } = this.productData;
    this.productForm.parcel = {
      weight: parcel?.weight ?? 1,
      height: parcel?.height ?? 1,
      length: parcel?.length ?? 1,
      width: parcel?.width ?? 1,
    };
    this.productForm.is_sale_direct = this.productData.is_sale_direct;
  }

  _onUploadImage() {
    this.isUploadImages = true;
    const newAttachments = this.productForm.attachments.filter((att) => att.type !== 'server' && att.type !== 'link');
    const newAttachmentsUpload = newAttachments.map((att) => att.data) as File[];
    const oldAttachments = this.productForm.attachments.filter((att) => att.type === 'server' || att.type === 'link');
    this.fileService
      .uploadFileList(newAttachmentsUpload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any) => {
          this.isUploadImages = false;
          this.isUpdating = true;
          const attachmentsForm =
            res?.data?.length > 0
              ? [...oldAttachments].concat(
                  res?.data.map((item: Attachment, i: number) => ({
                    type: 'server',
                    index: newAttachments[i].index,
                    data: item,
                  })),
                )
              : [...oldAttachments];
          const avatar = attachmentsForm.find((att) => att.index === this.productForm.avatarIndex)?.data as Attachment;
          const attachments = attachmentsForm.map((att) => att.data) as Attachment[];

          const newProduct = this.convertFormToData(attachments, avatar);
          this.isCreatingAttributes = false;

          if (this.productId === 'new') {
            this._onCreateProduct(newProduct);
          } else {
            this._onUpdateProduct(newProduct);
          }
        },
      });
  }

  convertFormToData(attachments: Attachment[], avatar?: Attachment) {
    const stocks: Stocks[] = [];
    let variants: Variation[] = [];

    // CONVERT TO STOCKS AND VARIANTS SERVER
    if (this.productForm.variants.length === 0) {
      stocks.push({
        product_id: this.productData?.id ?? '',
        id: this.productData?.stocks[0]?.id ?? '',
        order_first: 0,
        order_second: 0,
        price_after_tax: this.productForm.sale_price ?? 0,
        original_price_after_tax: this.productForm.price ?? 0,
        quantity: this.productForm.in_stock ?? 0,
        sku: this.productForm.sku,
      });
    } else {
      this.productForm.variants.forEach((v, i) => {
        const stock_variations: VariationValue[] = [...v.values].map((value, valueIdx) => {
          const pricing = this.productForm.pricings[valueIdx];
          const stock_variation = {
            name: value.data,
            pricing_order: i + 1,
            order: valueIdx + 1,
            image: attachments[pricing?.imageIndex],
          };
          return stock_variation;
        });
        const is_contains_image = stock_variations.some((stock_variation) => stock_variation.image);
        const variant: Variation = {
          name: v.key,
          stock_variations,
          order: i + 1,
          is_contains_image,
        };
        variants.push(variant);
      });

      this.productForm.pricings.forEach((pricing, pricingIdx) => {
        pricing.options.forEach((option, optionIdx) => {
          const curPricing = this.productData?.stocks?.find(
            (stock) =>
              stock.order_first === pricingIdx + 1 &&
              (this.productForm.variants.length === 2 ? stock.order_second === optionIdx + 1 : stock.order_second == 0),
          );
          const isSingleVariant =
            (this.productForm.pricings.length === 1 && this.productForm.variants.length === 1) ||
            (this.productForm.variants.length === 2 &&
              this.productForm.variants[0].values.length === 1 &&
              this.productForm.variants[1].values.length === 1);

          const stock: Stocks = {
            product_id: this.productData?.id ?? '',
            order_first: pricingIdx + 1,
            order_second: this.productForm.variants.length === 1 ? 0 : optionIdx + 1,
            quantity: isSingleVariant ? this.productForm.in_stock ?? 0 : option.in_stock,
            price_after_tax: isSingleVariant ? this.productForm.sale_price ?? 0 : option.sale_price,
            original_price_after_tax: isSingleVariant ? this.productForm.price ?? 0 : option.price,
            id: curPricing?.id,
            sku: option?.sku,
            image: option.image,
          };
          if (!curPricing) {
            delete stock.id;
          }
          stocks.push(stock);
        });
      });
    }

    const newProduct: CreateProductManagement = {
      sku: this.productForm.sku,
      name: this.productForm.name,
      is_public: this.productForm.is_public,
      stocks,
      prices: variants,
      hashtags: this.productForm.hashtags.filter((hashtag) => hashtag.length >= 2),
      attachments,
      avatar,
      catalog_ids: this.productForm.category?.id ? [this.productForm.category.id] : undefined,

      description: this.productForm.description,
      attribute_ids: this.productForm.attributes.map((attr) => attr.valueAttr.id).filter((id) => !!id),
      products_related: this.productForm.products_related.map((pro) => pro.id),
      products_combo: this.productForm.products_combo.map((pro) => pro.id),
      parcel: {
        weight: this.productForm.parcel.weight ?? 1,
        width: this.productForm.parcel.width ?? 1,
        height: this.productForm.parcel.height ?? 1,
        length: this.productForm.parcel.length ?? 1,
      },
      is_sale_direct: this.productForm.is_sale_direct,
      is_pricing_default: false,
      auto_confirm: this.productForm.auto_confirm,
      branch_id: this.productForm.branch_id,
      sale_area: this.productForm.sale_area ? this.productForm.sale_area.join(';') : ''
    };
    return newProduct;
  }

  _onCreateProduct(newProduct: CreateProductManagement) {
    this.stockService
      .productsObjectIdPost(newProduct as StockProductCreateDTO, this.shopId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: SeverityLevel.SUCCESS,
            detail: this.translator.transform(this.managementPrefix + 'toast.create-product-success'),
            icon: PrimeIcons.CHECK_CIRCLE,
          });
          this.isUpdating = false;
          this.router.navigate(['products'], {
            relativeTo: this.router.routerState.root.firstChild,
          });
          this.tabGuidelineService.onFinishStep(TabStepEnum.PRODUCT);
        },
        error: (err) => {
          this.isUpdating = false;
          this.messageService.add({
            severity: SeverityLevel.ERROR,
            detail:
              err?.error?.error?.message ??
              this.translator.transform(this.managementPrefix + 'toast.create-product-failed'),
            icon: PrimeIcons.CHECK_CIRCLE,
          });
        },
      });
  }

  _onUpdateProduct(newProduct: CreateProductManagement) {
    this.stockService
      .productsObjectIdIdPut(newProduct as StockProductCreateDTO, this.shopId, this.productId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: SeverityLevel.SUCCESS,
            detail: this.translator.transform(this.managementPrefix + 'toast.update-product-success'),
            icon: PrimeIcons.CHECK_CIRCLE,
          });
          this.isUpdating = false;
          this.onBack();
        },
        error: (err) => {
          this.isUpdating = false;
          this.messageService.add({
            severity: SeverityLevel.ERROR,
            detail:
              err?.error?.error?.message ??
              this.translator.transform(this.managementPrefix + 'toast.update-product-failed'),
            icon: PrimeIcons.CHECK_CIRCLE,
          });
        },
      });
  }

  _onSave() {
    this.isSubmitted = true;
    this.markAllControlsAsDirty([this.form.form]);

    if (this.form?.valid && this.productForm.attachments.length > 0 && this.isValidDes) {
      this._onUploadImage();
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  markAllControlsAsDirty(abstractControls: AbstractControl[]): void {
    abstractControls.forEach((abstractControl) => {
      if (abstractControl instanceof FormControl) {
        (abstractControl as FormControl).markAsDirty({ onlySelf: true });
      } else if (abstractControl instanceof FormGroup) {
        this.markAllControlsAsDirty(Object.values((abstractControl as FormGroup).controls));
      } else if (abstractControl instanceof FormArray) {
        this.markAllControlsAsDirty((abstractControl as FormArray).controls);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onBack() {
    this.router.navigate(['products'], {
      relativeTo: this.router.routerState.root.firstChild,
    });
  }

  navigateToProducts() {
    const url = `shops/${this.productData?.shop.id}/products/${this.productData.id}`;
    location.href = `${environment.FE_URL}${url}`;
  }
}
