import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RoleEnum } from 'src/app/core/enum/role.enum';
import { User } from 'src/app/core/models/interfaces/user';
import { AppService } from 'src/app/core/services/app.service';
import { CustomChatService } from 'src/app/core/services/chat.service';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { RoleService } from 'src/app/core/services/role.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isSmallScreen: boolean = false;
  @Input() user: User;

  readonly env = environment;
  readonly accountBlancePrefix = 'section-account-balance.';

  isProduction = environment.state === 'PRODUCTION';

  isShopActivated = true;
  is_information_verified = false;
  is_policy_verified = false;
  isSuspension = false;
  status: 'DENIED' | 'NEW' | 'ACCEPTED';
  legality_status: 'DENIED' | 'NEW' | 'ACCEPTED';

  shopURL = '';
  shopId: string = '';
  url = '';
  chatCount: string = '0';

  private destroy$ = new Subject<void>(); // For cleanup on component destruction

  get isNew() {
    return this.status === 'NEW' || this.legality_status === 'NEW';
  }

  get isDenied() {
    return !this.isNew && (this.status === 'DENIED' || this.legality_status === 'DENIED');
  }

  constructor(
    private appService: AppService,
    private router: Router,
    private roleService: RoleService,
    private translator: CustomTranslateService,
    private customChatService: CustomChatService,
  ) {}

  isPermissionAccountBalance() {
    return this.roleService.userRolesValue.some((role) => [RoleEnum.E_OWNER].includes(role));
  }

  isPermissionChat() {
    return (
      Array.isArray(this.roleService.userRolesValue) &&
      this.roleService.userRolesValue.some((role) => role === RoleEnum.E_OWNER)
    );
  }
  setIsSidebar(isSidebar: boolean) {
    this.appService.setIsSidebar(isSidebar);
  }

  ngOnInit(): void {
    this.shopURL = `${this.env.FE_URL_SALE}`;
    this.url = this.router.url;
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
      },
    });

    this.appService.getShopData().subscribe((data) => {
      if (data) {
        this.is_policy_verified = data.is_policy_verified;
        this.isShopActivated = data?.is_market_public;
        this.is_information_verified = data.is_information_verified;
        this.status = data?.accepted_status;
        this.legality_status = data?.legality_status;
        this.shopId = data.id;
      }
    });

    this.appService.getIsSuspension().subscribe((isSuspension) => {
      this.isSuspension = isSuspension;
    });

    this.customChatService.getChatCountEvent().subscribe({
      next: (isReload) => {
        if (isReload) {
          this.getChatCount();
        }
      },
    });

    // Call getChatCount() every 15 seconds
    interval(15000)
      .pipe(takeUntil(this.destroy$)) // Automatically stop interval when component is destroyed
      .subscribe(() => {
        this.getChatCount();
      });
  }

  getChatCount() {
    this.customChatService.getPendingChat(this.shopId, 'SHOP', 'PENDING').subscribe({
      next: (count) => {
        this.chatCount = count.data?.toString();
      },
      error: () => {
        this.chatCount = '0';
      },
    });
  }

  changeLanguage(language: string) {
    this.translator.setLanguage(language);
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

  handleSignOut = () => {
    const frame = document.getElementById('socialLogin');
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');

    if (this.isIFrame(frame) && frame.contentWindow) {
      const messageData = {
        type: 'disconnect',
        data: {
          token: localStorage.getItem('accessToken'),
          user_id: auth?.id,
        },
      };
      frame.contentWindow.postMessage(messageData, {
        targetOrigin: environment.LOGIN_URL,
      });
    }
    localStorage.clear();
    window.location.href = `${environment.LOGIN_URL}/login`;
  };

  // Clean up the interval when the component is destroyed
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
