<div class="center-container">
  <div id="container">
    <div class="image-wrapper" [class.loading]="isLoading">
      <div class="loading-spinner flex gap-2 items-center" *ngIf="isLoading">
        <i class="pi pi-spin pi-spinner text-blue-light-500" style="font-size: 1.5rem"></i>
        <div>{{ orderPrefix + "printing-order" | translate | sentenceCase }}</div>
      </div>
      <button *ngIf="!isLoading" (click)="printImage()">{{ "common.print" | translate | sentenceCase }}</button>
      <img [src]="imageSrc" alt="Image" (load)="onImageLoad()" (error)="onImageError()" />
    </div>
  </div>
</div>
