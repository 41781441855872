<div *ngIf="categoryNode" #header class="flex items-center justify-between gap-2 pb-2 px-1">
  <p-breadcrumb [model]="breadCrumbItems"></p-breadcrumb>
  <button
    class="group flex items-center gap-1 p-2 text-primary-700 text-sm font-semibold min-w-max outline-none"
    (click)="showAddProductDialog()"
  >
    <i class="pi pi-plus font-semibold" style="font-size: 0.75rem; font-weight: bold"></i>
    <span class="group-hover:underline">
      {{ categoryPrefix + "add-product" | translate | sentenceCase }}
    </span>
  </button>
</div>

<div class="relative">
  <ng-container *ngIf="productData?.length; else productsNotFound">
    <div class="relative grid gap-4">
      <p-table
        #table
        class="border border-gray-300 rounded-lg overflow-hidden"
        [value]="productData"
        [(selection)]="selectedProducts"
        dataKey="id"
        [tableStyle]="{ 'min-width': '35rem' }"
        (selectionChange)="getSelectionChange($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>
              <div class="text-xs font-medium text-gray-600">
                {{ "common.product" | translate | sentenceCase }}
              </div>
            </th>
            <th style="width: 8rem">
              <span class="flex items-center justify-end text-xs font-medium text-gray-600">
                {{ "common.price" | translate | sentenceCase }}
              </span>
            </th>
            <th style="width: 8rem" class="truncate">
              <span class="flex items-center justify-end text-xs font-medium text-gray-600 min-w-max">
                {{ categoryPrefix + "in-stock" | translate | sentenceCase }}
              </span>
            </th>
            <th style="width: 5rem">
              <span
                *ngIf="!hideRemoveButton"
                class="flex items-center justify-end text-xs font-medium text-gray-600 min-w-max"
              >
                {{ "common.action" | translate | sentenceCase }}
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr class="text-sm font-normal">
            <td>
              <p-tableCheckbox [value]="product"></p-tableCheckbox>
            </td>
            <td>
              <span class="flex gap-3 items-center" title="{{ product?.name }}">
                <img
                  class="inline-block min-w-[2rem] w-8 h-8 object-cover object-center"
                  [src]="getImgUrl(product.avatar?.id)"
                  [title]="product?.avatar?.description"
                  onerror="this.src='assets/imgs/default/image.webp'"
                />
                <span class="max-w-[10rem] lg:max-w-[15rem] xl:max-w-[20rem] 2xl:max-w-[25rem] line-clamp-2">
                  {{ product?.name }}
                </span>
              </span>
            </td>
            <td>
              <div class="flex items-center justify-end">
                {{ product?.price_after_tax | currency: " " : "symbol" : "0.0-2" }}
              </div>
            </td>
            <td>
              <div class="flex items-center justify-end">
                {{ product?.quantity }}
              </div>
            </td>
            <td>
              <div *ngIf="!hideRemoveButton" class="flex items-center justify-end">
                <button
                  class="text-error-700 hover:underline min-w-max text-sm font-semibold focus:outline-none"
                  (click)="handleDelete($event, product)"
                >
                  {{ actionPrefix + "remove" | translate | sentenceCase }}
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>

  <ng-template #productsNotFound>
    <div *ngIf="categoryNode" class="grid gap-4 justify-center py-20">
      <div class="flex justify-center">
        <img src="/assets/imgs/backpack.webp" class="inline-block min-w-[3rem] w-12 h-12 object-cover object-center" />
      </div>
      <div class="text-gray-900 font-semibold">
        {{ categoryDescription + "no-product-found" | translate | sentenceCase }}
      </div>
      <div class="flex items-center justify-center">
        <button class="btn btn-md btn-primary" (click)="showAddProductDialog()">
          {{ categoryPrefix + "add-product" | translate | sentenceCase }}
        </button>
      </div>
    </div>

    <div *ngIf="!categoryNode" class="grid gap-4 justify-center py-20">
      <div class="flex justify-center">
        <img src="/assets/imgs/backpack.webp" class="inline-block min-w-[3rem] w-12 h-12 object-cover object-center" />
      </div>
      <div class="text-gray-900 font-semibold">
        {{ categoryPrefix + "choose-a-category" | translate | sentenceCase }}
      </div>
    </div>
  </ng-template>

  <div
    *ngIf="isDataLoading"
    class="absolute z-10 flex top-0 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
  >
    <p-progressSpinner styleClass="w-[2rem] h-[2rem]" strokeWidth="2" animationDuration=".5s"></p-progressSpinner>
  </div>
</div>

<!-- Add product dialog -->
<p-dialog
  class="relative"
  header="Header"
  [(visible)]="isDialogVisible"
  [breakpoints]="{ '1280px': '70vw', '1024px': '80vw', '768px': '90vw' }"
  [style]="{ width: '60vw' }"
  [modal]="true"
  [draggable]="false"
  [maximizable]="true"
  (onHide)="cancelAddProduct()"
>
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">
      {{ categoryPrefix + "add-product" | translate | sentenceCase }}
    </span>
  </ng-template>

  <app-add-product
    [categoryNode]="categoryNode"
    [isPending]="isAddDialogPending"
    [(editData)]="editData"
  ></app-add-product>

  <ng-template pTemplate="footer">
    <button (click)="cancelAddProduct()" class="btn btn-md btn-secondary-gray min-w-[5rem]">
      {{ actionPrefix + "cancel" | translate | sentenceCase }}
    </button>
    <button (click)="confirmAdd()" class="btn btn-md btn-primary min-w-[5rem]" [disabled]="!editData.addData.length">
      {{ actionPrefix + "add" | translate | sentenceCase }}
    </button>
  </ng-template>
</p-dialog>
<!-- End add product dialog -->
