import { AfterViewInit, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomTranslateService } from 'src/app/core/services/custom-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-shipment-print',
  templateUrl: './order-shipment-print.component.html',
  styleUrls: ['./order-shipment-print.component.scss'],
})
export class OrderShipMentComponent implements AfterViewInit {
  readonly orderPrefix = 'section-order-management.';
  imageSrc = '';
  isLoading = true;

  route = inject(ActivatedRoute);

  constructor(private translator: CustomTranslateService) {
    const language = localStorage.getItem('lang');
    this.translator.setLanguage(language ?? 'en');
    this.imageSrc = environment.GOSHIP_PRINTDOMAIN + (this.route.snapshot.paramMap.get('code') || 'demo');
  }

  ngAfterViewInit(): void {
    this.printImage();
  }

  onImageLoad(): void {
    this.isLoading = false;
  }

  onImageError(): void {
    this.isLoading = false;
  }

  public printImage(): void {
    const imageUrcPrint = this.imageSrc + '&useCache=true';
    const printWindow = window.open('', '', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Image</title>
            <style>
              @media print {
                @page { margin: 0; }
                body { margin: 0; padding: 0; }
                img { width: 100vw; height: 100vh; object-fit: contain; }
              }
              @media screen {
                body { text-align: center; margin: 0; }
                img { max-width: 100%; max-height: 100vh; }
              }
            </style>
          </head>
          <body>
            <img src="${imageUrcPrint}" />
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                };
              };
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  }
}
