import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ChipsAddEvent } from 'primeng/chips';

import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { ProductGet } from 'src/app/core/models/interfaces/product/product-detail';
import { ProductFormType } from '../../pages/product-edit/product-edit.model';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class GeneralInfoComponent {
  SoctripIcons = SoctripIcons;
  actionPrefix = 'section-action.';
  managementPrefix = 'section-product-n-food-management.';
  descriptionPrefix = 'text-description.';
  isNameExceedMaxLength: boolean = false;
  nationwideId = '00000000-0000-0000-0000-000000000000';

  @Input({ required: true }) productForm: ProductFormType;
  @Input({ required: true }) attachments: any[];
  @Input({ required: true }) categories: any[];
  @Input({ required: true }) deliveryAreas: any[];
  @Input() productData: ProductGet;
  @Input() isViewMode: boolean = false;
  @Input() isIframe: boolean = false;
  @Input() isSubmitted: boolean = false;

  @Output() attachmentsChange = new EventEmitter<any[]>();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges() {
    if (this.productForm.name.length > 120) {
      this.isNameExceedMaxLength = true;
    } else {
      this.isNameExceedMaxLength = false;
    }
  }

  getSafeHTML(note: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(note);
  }

  onAddChip(event: ChipsAddEvent) {
    const MAX_LENGTH_HASHTAG = 20;
    const newHashtag = event.value;

    if (!this.productForm.hashtags.includes(newHashtag) && this.productForm.hashtags.length < MAX_LENGTH_HASHTAG) {
      this.productForm.hashtags.push(newHashtag);
    }

    if (this.productForm.hashtags.length > MAX_LENGTH_HASHTAG) {
      this.productForm.hashtags.pop();
    }
  }

  onAttachmentsChange(e: any[]) {
    this.attachments = e;
    this.attachmentsChange.emit(this.attachments);
  }

  onSelectCategory(event: any) {
    const type = event.node?.type;
    if (type === 'E_TICKET') {
      const { weight, width, length, height } = this.productForm.parcel;
      this.productForm.parcel = {
        weight: weight || 100,
        width: width || 100,
        length: length || 100,
        height: height || 100,
      };
    }
  }

  onSaleAreaChange(event: any) {
    if (event.value.includes(this.nationwideId)) {
      this.productForm.sale_area = [this.nationwideId];
    } else {
      this.productForm.sale_area = this.productForm.sale_area.filter((id:string) => id !== this.nationwideId);
    }
  }

  get isNationwideSelected(): boolean {
    return this.productForm.sale_area.includes(this.nationwideId);
  }

  checkCreatedEditedBy() {
    let results = false;
    if (this.productData.status === 'NEW') {
      if (this.productData?.shopDetailDTO?.created_by === this.productData?.created_by) {
        results = true;
      }
    }

    if (this.productData.status === 'EDIT') {
      if (this.productData?.shopDetailDTO?.created_by === this.productData?.updated_by) {
        results = true;
      }
    }

    return results;
  }

  scrollToBottom() {
    setTimeout(() => {
      const multiSelectElement = document.querySelector('.p-multiselect .p-multiselect-label');
      if (multiSelectElement) {
        multiSelectElement.scrollTop = multiSelectElement.scrollHeight;
      }
    }, 100);
  }
}
